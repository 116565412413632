import React from 'react';
import {coinDefinitions, CoinId, CoinFace, DisplaySide} from "../../../lib/sharedConstants";
import {Col, ListGroup, Row} from "react-bootstrap";
import {Step} from "../../../modules/coins";

interface Props {
    displaySide: DisplaySide;
    coinId: CoinId;
    face: CoinFace;
    isSelected: boolean;
    className?: string;
}

export const SecondaryCoin: React.FunctionComponent<Props> = (props: Props) => {
    let {
        coinId,
        face,
        isSelected,
        displaySide,
        className,
    } = props;

    let def = coinDefinitions[coinId];

    switch (displaySide) {
        case DisplaySide.Left:
            return <Row className={`${className}`}>
                <Col sm={12} md={4}>
                    <h2>{def.name} {face} icon</h2>
                </Col>
                <Col sm={12} md={8} className={`selection-box left-side ${isSelected ? 'selected' : 'unselected'}`}>
                    <h2 className='selected-side'>{def.name}</h2>
                </Col>
            </Row>;
        case DisplaySide.Right:
            return <Row className={`${className}`}>
                <Col sm={12} md={8} className={`selection-box right-side ${isSelected ? 'selected' : 'unselected'}`}>
                    <h2 className='selected-side'>{def.name}</h2>
                </Col>
                <Col sm={12} md={4}>
                    <h2>{def.name} {face} icon</h2>
                </Col>
            </Row>;
        default:
            throw new Error(`Invalid display side ${displaySide}`);
    }
}
