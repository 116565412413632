import {combineReducers} from 'redux'
import { modalReducer } from './modal'
import { coinReducer } from './coins'
import { History } from 'history'
import {connectRouter} from "connected-react-router";
import {rootReducer} from "../store";

const createRootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    modalReducer,
    coinReducer,
});

export default createRootReducer;
export type AppState = ReturnType<typeof rootReducer>
