import {Step} from "../modules/coins";
import React from "react";

export const HEADS = 0;
export const TAILS = 1;

export const LOCAL_STORAGE_PREFIX = 'four_coins_';

export enum Color {
    White = '@@color/White',
    Black = '@@color/Black',
}

export enum CoinFace {
    Heads = '@@coinFace/Heads',
    Tails = '@@coinFace/Tails',
}

export enum DisplaySide {
    Left = '@@displaySide/Left',
    Right = '@@displaySide/Right',
}

export const getCoinImage = (face: CoinFace, color: Color): string => {
    let faceText = face === CoinFace.Heads ? 'heads' : 'tails';
    switch (color) {
        case Color.White:
            return `silver_${faceText}.jpg`;
        case Color.Black:
        default:
            return `taft_${faceText}.jpg`;
    }
};

/**
 * Reindex coin/slot values so they're in slot order for rendering
 */
export const getCoinsBySlot = (coinSlots: number[]): number[] => {
    let coinsBySlot: number[] = [];
    coinSlots.forEach((slotIndex, coinIndex) => {
        coinsBySlot[slotIndex] = coinIndex;
    });
    return coinsBySlot;
};

export const flip = (currentFace: CoinFace): CoinFace => currentFace === CoinFace.Heads ? CoinFace.Tails : CoinFace.Heads;

export const coinSelectionEqual = (left: CoinSelection, right: CoinSelection) => {
    return left.id == right.id && left.face == right.face;
}

export const getAspectsDisplayString = (def: CoinDefinition): String =>
    `${def.aspects.slice(0, -1).join(", ")}, and ${def.aspects[def.aspects.length - 1]}`;

export enum GroupId {
    Person = '@@group/Person',
    Gear = '@@group/Gear',
    Lightbulb = '@@group/Lightbulb',
}

export interface GroupDefinition {
    id: GroupId;
    filename: string;
}

export const groupDefinitions: Record<GroupId, GroupDefinition> = {
    [GroupId.Gear]: {
        id: GroupId.Gear,
        filename: 'silver_heads.jpg',
    },
    [GroupId.Person]: {
        id: GroupId.Person,
        filename: 'silver_tails.jpg',
    },
    [GroupId.Lightbulb]: {
        id: GroupId.Lightbulb,
        filename: 'gold_heads.jpg',
    },
};

export enum CoinId {
    Balance = '@@coin/Balance',
    Exploration = '@@coin/Exploration',
    Direction = '@@coin/Direction',
    Focus = '@@coin/Focus',
}

export const coinSelectionSteps = {
    [Step.FirstSet]: [CoinId.Balance, CoinId.Focus],
    [Step.SecondSet]: [CoinId.Exploration, CoinId.Direction],
};

export interface CoinDefinition {
    id: CoinId;
    name: string;
    color: Color;
    aspects: string[];
    areas: Record<CoinFace, CoinArea>
}

export interface CoinArea {
    face: CoinFace;
    descriptions: string[];
    group: GroupId;
}

export interface CoinSelection {
    id: CoinId;
    face: CoinFace;
}

export const coinDefinitions: Record<CoinId, CoinDefinition> = {
    [CoinId.Balance]: {
        id: CoinId.Balance,
        name: 'Balance',
        color: Color.White,
        aspects: ['Networks', 'Symmetry', 'Structure', 'Rules'],
        areas: {
            [CoinFace.Heads]: {
                face: CoinFace.Heads,
                descriptions: ['Friends', 'My Social Groups', 'Others', 'Politics', 'Culture'],
                group: GroupId.Person,
            },
            [CoinFace.Tails]: {
                face: CoinFace.Tails,
                descriptions: ['Facts', 'Thought', 'Patterns', 'Objects', 'My Knowledge'],
                group: GroupId.Gear,
            },
        }
    },
    [CoinId.Exploration]: {
        id: CoinId.Exploration,
        name: 'Exploration',
        color: Color.Black,
        aspects: ['Potential', 'Experimentation', 'Enjoyment', 'Quest'],
        areas: {
            [CoinFace.Heads]: {
                face: CoinFace.Heads,
                descriptions: ['Ideas', 'for Novelty', 'Perspectives', 'Possibilities', 'Wonder'],
                group: GroupId.Lightbulb,
            },
            [CoinFace.Tails]: {
                face: CoinFace.Tails,
                descriptions: ['Myself', 'My Body', 'My Senses', 'for Peacefulness', 'Sentimentality'],
                group: GroupId.Person,
            },
        }
    },
    [CoinId.Direction]: {
        id: CoinId.Direction,
        name: 'Direction',
        color: Color.Black,
        aspects: ['Momentum', 'Perception', 'Movement', 'Presence'],
        areas: {
            [CoinFace.Heads]: {
                face: CoinFace.Heads,
                descriptions: ['Myself', 'Power', 'Others', 'My Surroundings', 'Action'],
                group: GroupId.Person,
            },
            [CoinFace.Tails]: {
                face: CoinFace.Tails,
                descriptions: ['Ideas', 'Thought', 'Trends', 'Foresight', 'My Goals'],
                group: GroupId.Lightbulb,
            },
        }
    },
    [CoinId.Focus]: {
        id: CoinId.Focus,
        name: 'Focus',
        color: Color.White,
        aspects: ['Condition', 'Context', 'Situation', 'Interpretation'],
        areas: {
            [CoinFace.Heads]: {
                face: CoinFace.Heads,
                descriptions: ['Objects', 'The Rules', 'Others', 'for Leverage', 'Efficiency'],
                group: GroupId.Gear,
            },
            [CoinFace.Tails]: {
                face: CoinFace.Tails,
                descriptions: ['Myself', 'My Closest Friends', 'My Family', 'My Beliefs', 'Morals'],
                group: GroupId.Person,
            },
        }
    },
};

// /** @type {string[][]} slots */
// export const slots = [
//     [
//         // Heads is always first
//         'I am very good at thinking this way, and I prioritize this logic above anything else.',
//         'I want to be very good at this but history has shown otherwise; I want help here so that I can develop this better.',
//     ],
//     [
//         'I enjoy this style of thinking a lot, and feel confident in my abilities to use information this way.',
//         'I am poor-to-average at this but value those who are good at it.',
//     ],
//     [
//         'I am very good at thinking this way, but I don’t really prioritize this logic much.',
//         'This is my bane and I avoid this at all costs.',
//     ],
//     [
//         'I am pretty solid here, but really don\'t care much about this.',
//         'I\'m not great at this and I still don’t care about this too much.',
//     ],
// ];