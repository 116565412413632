import React, {Component} from 'react';
import {coinDefinitions, CoinSelection, getAspectsDisplayString} from "../../../../lib/sharedConstants";
import {CenteredRow} from "../../../../lib/components/CenteredRow";
import {CenteredFlexCol} from "../../../../lib/components/CenteredFlexCol";

interface Props {
    selection: CoinSelection;
}

export class ResultPage extends Component<Props> {
    render() {
        let {
            selection,
        } = this.props;
        let def = coinDefinitions[selection.id];
        let areaDescriptions = def.areas[selection.face].descriptions;

        return <>
            <CenteredRow>
                <CenteredFlexCol sm={12}>
                    <h2>You identify most with:</h2>
                </CenteredFlexCol>
            </CenteredRow>
            <CenteredRow>
                <CenteredFlexCol sm={12}>
                    <h4>The {def.name}, {getAspectsDisplayString(def)} of:</h4>
                </CenteredFlexCol>
            </CenteredRow>
            <CenteredRow>
                <CenteredFlexCol sm={12}>
                    <h4>{areaDescriptions.join(", ")}</h4>
                </CenteredFlexCol>
            </CenteredRow>
        </>;
    }
}
