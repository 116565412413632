import {CoinId, CoinSelection, CoinFace,} from '../lib/sharedConstants';

// ------------------------------------
// Constants
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------
export enum CoinActions {
    SelectFirst = '@@coin/SelectFirst',
    SelectSecond = '@@coin/SelectSecond',
    SelectCoinFaces = '@@coin/SelectCoinFaces',
    SelectNemesis = '@@coin/SelectNemesis',
}

export enum SagaCoinActions {
    // Load = '@@coinSaga/Load',
}

interface CoinAction {
    type: CoinActions;
}

interface CoinSagaAction {
    type: SagaCoinActions;
}

interface SelectFirstCoinAction extends CoinAction {
    type: CoinActions.SelectFirst;
    coinId: CoinId,
}

export const selectFirstCoin = (coinId: CoinId): SelectFirstCoinAction => {
    return {
        type: CoinActions.SelectFirst,
        coinId,
    };
};

interface SelectSecondCoinAction extends CoinAction {
    type: CoinActions.SelectSecond;
    coinId: CoinId,
}

export const selectSecondCoin = (coinId: CoinId): SelectSecondCoinAction => {
    return {
        type: CoinActions.SelectSecond,
        coinId,
    };
};

interface SelectCoinFacesAction extends CoinAction {
    type: CoinActions.SelectCoinFaces;
    selectedCoinFaces: CoinSelection[];
}

export const selectCoinFaces = (selectedCoinFaces: CoinSelection[]): SelectCoinFacesAction => {
    return {
        type: CoinActions.SelectCoinFaces,
        selectedCoinFaces,
    };
};

interface SelectNemesisAction extends CoinAction {
    type: CoinActions.SelectNemesis;
    coinId: CoinId;
    face: CoinFace;
}

export const selectNemesis = (coinId: CoinId, face: CoinFace): SelectNemesisAction => {
    return {
        type: CoinActions.SelectNemesis,
        coinId,
        face,
    };
};

export enum Step {
    FirstSet = '@@step/FirstSet',
    SecondSet = '@@step/SecondSet',
    Flip = '@@step/Flip',
    Split = '@@step/Split',
    Nemesis = '@@step/Nemesis',
    Result = '@@step/Result',
}

export const indexedSteps = [
    Step.FirstSet,
    Step.SecondSet,
    Step.Flip,
    Step.Split,
    Step.Nemesis,
    Step.Result,
];

export type CoinActionTypes = SelectFirstCoinAction |
    SelectSecondCoinAction |
    SelectCoinFacesAction |
    SelectNemesisAction;

export interface CoinState {
    firstSelectedCoin: CoinId | null;
    secondSelectedCoin: CoinId | null;
    selectedCoinFaces: CoinSelection[];
    selectedNemesis: CoinSelection | null;
}

const initialState: CoinState = {
    firstSelectedCoin: null,
    secondSelectedCoin: null,
    selectedCoinFaces: [],
    selectedNemesis: null,
};

export function coinReducer(state = initialState, action: CoinActionTypes): CoinState {
    switch (action.type) {
        case CoinActions.SelectFirst:
            return {
                ...state,
                firstSelectedCoin: action.coinId,
            };
        case CoinActions.SelectSecond:
            return {
                ...state,
                secondSelectedCoin: action.coinId,
            };
        case CoinActions.SelectCoinFaces:
            return {
                ...state,
                selectedCoinFaces: action.selectedCoinFaces,
                selectedNemesis: null,
            };
        case CoinActions.SelectNemesis:
            return {
                ...state,
                selectedNemesis: {
                    id: action.coinId,
                    face: action.face,
                },
                selectedCoinFaces: [],
            };
        default:
            return state;
    }
}
