import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import {coinDefinitions, CoinFace, CoinId, DisplaySide, flip} from "../../../../lib/sharedConstants";
import {renderGroupIcon} from "../../../../lib/renderUtils";
import {CenteredRow} from "../../../../lib/components/CenteredRow";
import {DualSelectionPanel} from "../panels/DualSelectionPanel";
import {SelectorProps} from "../../index";
import {indexedSteps} from "../../../../modules/coins";
import {RouteComponentProps} from "react-router-dom";

interface ComponentProps {
    leftCoin: CoinId;
    rightCoin: CoinId;
    displayBothFaces: boolean;
}

interface MatchParams {
    step: string;
}

type Props = ComponentProps & SelectorProps & RouteComponentProps<MatchParams>;

export class DualSelectionPage extends Component<Props> {
    render() {
        let {
            leftCoin,
            rightCoin,
            // displayBothFaces,
            selectedCoinFaces,
        } = this.props;
        let {
            step: stepIndex,
        } = this.props.match.params;

        let stepEnum = indexedSteps[parseInt(stepIndex, 10) - 1];
        let leftDef = coinDefinitions[leftCoin];
        let rightDef = coinDefinitions[rightCoin];

        let leftTopFace = CoinFace.Heads;
        let leftBottomFace = flip(leftTopFace);
        // Right side faces are inverse of left faces
        let rightTopFace = leftBottomFace;
        let rightBottomFace = leftTopFace;
        return <>
            <Row>
                <Col className='left-half-screen' sm={12} md={6}>
                    <DualSelectionPanel
                        topCoin={{
                            id: leftCoin,
                            face: leftTopFace,
                        }}
                        bottomCoin={{
                            id: leftCoin,
                            face: leftBottomFace,
                        }}
                        // displayBothFaces={displayBothFaces}
                        displaySide={DisplaySide.Left}
                        selected={selectedCoinFaces}
                        step={stepEnum}
                        {...this.props}
                    />
                </Col>
                <Col className='right-half-screen' sm={12} md={6}>
                    <DualSelectionPanel
                        topCoin={{
                            id: rightCoin,
                            face: rightTopFace,
                        }}
                        bottomCoin={{
                            id: rightCoin,
                            face: rightBottomFace,
                        }}
                        // displayBothFaces={displayBothFaces}
                        displaySide={DisplaySide.Right}
                        selected={selectedCoinFaces}
                        step={stepEnum}
                        {...this.props}
                    />
                </Col>
            </Row>
            <CenteredRow>
                Select the coin you relate to the most. Most people feel more comfortable with either the
                {renderGroupIcon(leftDef.areas[CoinFace.Heads].group)} group or the
                {renderGroupIcon(rightDef.areas[CoinFace.Heads].group)} group. This is normal.
            </CenteredRow>
        </>;
    }
}
