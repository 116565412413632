import React from 'react';
import {Row} from 'react-bootstrap';

interface Props {
  className?: string;
  style?: Object;
}

export const CenteredRow: React.SFC<Props> = ({ style = {}, className = '', children }) => {
  return <Row style={style} className={`${className} justify-content-center align-items-center`}>
    {children}
  </Row>;
};