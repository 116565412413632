import {applyMiddleware, compose, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import {createBrowserHistory} from "history";
import createRootReducer from './modules'
import {modalSagas} from "./sagas/modalSagas";

export const history = createBrowserHistory();

const initialState = {};
const enhancers = [];
const sagaMiddleware = createSagaMiddleware();
const middleware = [routerMiddleware(history), sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

export const rootReducer = createRootReducer(history);
const store = createStore(
    rootReducer,
    initialState,
    composedEnhancers
);

sagaMiddleware.run(modalSagas);

export default store;
