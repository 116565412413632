import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import {coinDefinitions, CoinSelection, coinSelectionEqual, DisplaySide, flip} from "../../../../lib/sharedConstants";
import {Aspect} from "../Aspect";
import {SecondaryCoin} from "../SecondaryCoin";
import {CenteredRow} from "../../../../lib/components/CenteredRow";
import {indexedSteps, Step} from "../../../../modules/coins";
import {Link} from "react-router-dom";
import {SelectorProps} from "../../index";

interface Props {
    displaySide: DisplaySide;
    topCoin: CoinSelection;
    bottomCoin: CoinSelection;
    selected: CoinSelection[];
    step: Step;
}

export class DualSelectionPanel extends Component<Props & SelectorProps> {
    render() {
        let {
            topCoin,
            selected,
        } = this.props;
        let def = coinDefinitions[topCoin.id];

        return <>
            <CenteredRow className='p-1'>
                <h4>The {def.name}, {def.aspects.slice(0, -1).join(", ")},
                    and {def.aspects.slice(-1)} of:</h4>
            </CenteredRow>
            <Row className='p-1'>
                {this.renderContent()}
            </Row>
        </>;
    }

    renderContent() {
        let {
            selectedCoinFaces,
            displaySide,
            selected,
            step,
        } = this.props;
        // If no faces have been selected, we're at the "flip" step and need to show all faces
        let displayBothFaces = step === Step.Flip;
        let isCoinFaceSelected = (coin: CoinSelection): boolean => selectedCoinFaces.some((value) => coinSelectionEqual(value, coin));
        let isCoinFaceVisible = (coin: CoinSelection): boolean => displayBothFaces || isCoinFaceSelected(coin);
        let nextStepIndex: number;
        switch (step) {
            case Step.Flip:
                nextStepIndex = indexedSteps.indexOf(Step.Split) + 1;
                break;
            case Step.Split:
                nextStepIndex = indexedSteps.indexOf(Step.Result) + 1;
                break;
            default:
                throw new Error(`Invalid step ${step} for DualSelectionPage`);
        }

        switch (displaySide) {
            case DisplaySide.Left:
                return <>
                    {this.renderAspects(isCoinFaceVisible)}
                    {this.renderCoins(nextStepIndex, isCoinFaceSelected, isCoinFaceVisible)}
                </>;
            case DisplaySide.Right:
                return <>
                    {this.renderCoins(nextStepIndex, isCoinFaceSelected, isCoinFaceVisible)}
                    {this.renderAspects(isCoinFaceVisible)}
                </>;
        }
    }

    renderAspects(
        isCoinFaceVisible: (coin: CoinSelection) => boolean,
    ) {
        let {
            topCoin,
            bottomCoin,
        } = this.props;
        return <Col sm={12} md={6}>
            <Aspect className={isCoinFaceVisible(topCoin) ? '' : 'hidden'}
                    coinId={topCoin.id}
                    face={topCoin.face}
            />
            <Aspect className={isCoinFaceVisible(bottomCoin) ? '' : 'hidden'}
                    coinId={bottomCoin.id}
                    face={bottomCoin.face}
            />
        </Col>
    }

    renderCoins(
        nextStepIndex: number,
        isCoinFaceSelected: (coin: CoinSelection) => boolean,
        isCoinFaceVisible: (coin: CoinSelection) => boolean,
    ) {
        let {
            topCoin,
            bottomCoin,
            displaySide,
        } = this.props;
        return <Col sm={12} md={6}>
            <Link
                to={`${nextStepIndex}`}
                onClick={this.clickHandler.bind(this, topCoin)}
            >
                <SecondaryCoin className={isCoinFaceVisible(topCoin) ? '' : 'hidden'}
                               coinId={topCoin.id}
                               face={topCoin.face}
                               isSelected={isCoinFaceSelected(topCoin)}
                               displaySide={displaySide}
                />
            </Link>
            <Link
                to={`${nextStepIndex}`}
                onClick={this.clickHandler.bind(this, bottomCoin)}
            >
                <SecondaryCoin className={isCoinFaceVisible(bottomCoin) ? '' : 'hidden'}
                               coinId={bottomCoin.id}
                               face={bottomCoin.face}
                               isSelected={isCoinFaceSelected(bottomCoin)}
                               displaySide={displaySide}
                />
            </Link>
        </Col>
    }

    clickHandler(coinFace: CoinSelection) {
        let {
            firstSelectedCoin,
            secondSelectedCoin,
            selectCoinFaces,
            step,
        } = this.props;
        switch (step) {
            case Step.Flip:
                let otherCoinId = firstSelectedCoin === coinFace.id ? secondSelectedCoin : firstSelectedCoin
                if (otherCoinId === null) {
                    throw new Error(`Failed to find both first and second selected coins on Flip step`);
                }

                // On the Flip step, we choose which face of either coin we want, effectively choosing two faces
                let otherFace: CoinSelection = {
                    id: otherCoinId,
                    face: flip(coinFace.face),
                }
                selectCoinFaces([coinFace, otherFace]);
                break;
            case Step.Split:
                // On the Split step, we choose the final face we want
                selectCoinFaces([coinFace]);
                break;
            default:
                throw new Error(`Invalid step ${step} for SingleSelectionPanel`);
        }
    }
}
