import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import {coinDefinitions, CoinId, CoinFace, DisplaySide, GroupId} from "../../../../lib/sharedConstants";
import {Aspect} from "../Aspect";
import {PrimaryCoin} from "../PrimaryCoin";
import {renderGroupIcon} from "../../../../lib/renderUtils";
import {SingleSelectionPanel} from "../panels/SingleSelectionPanel";
import {CenteredRow} from "../../../../lib/components/CenteredRow";
import {SelectorProps} from "../../index";
import {Step} from "../../../../modules/coins";

interface Props {
    leftCoin: CoinId;
    rightCoin: CoinId;
    step: Step;
}

const getCoinGroupSet = (coin: CoinId): GroupId[] => {
    let def = coinDefinitions[coin];
    return Object.values(def.areas).map(area => area.group).sort();
};

export class SingleSelectionPage extends Component<Props & SelectorProps> {
    render() {
        let {
            leftCoin,
            rightCoin,
            step,
        } = this.props;
        let leftDef = coinDefinitions[leftCoin];
        let rightDef = coinDefinitions[rightCoin];

        if (getCoinGroupSet(leftCoin).length != getCoinGroupSet(rightCoin).length) {
            throw new Error(`Groups did not match between ${leftCoin} and ${rightCoin}`);
        }

        return <>
            <Row>
                <SingleSelectionPanel step={step} coinId={leftCoin} displaySide={DisplaySide.Left} {...this.props}/>
                <SingleSelectionPanel step={step} coinId={rightCoin} displaySide={DisplaySide.Right} {...this.props}/>
            </Row>
            <CenteredRow>
                Select the coin you relate to the most. Most people feel more comfortable with either the
                {renderGroupIcon(leftDef.areas[CoinFace.Heads].group)} group or the
                {renderGroupIcon(rightDef.areas[CoinFace.Heads].group)} group. This is normal.
            </CenteredRow>
        </>;
    }
}
