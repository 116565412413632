import React from 'react';
import {coinDefinitions, CoinId, CoinFace} from "../../../lib/sharedConstants";
import {Col, ListGroup, Row} from "react-bootstrap";
import {CenteredFlexCol} from "../../../lib/components/CenteredFlexCol";

interface Props {
    coinId: CoinId;
    face: CoinFace;
    className?: string;
}

export const Aspect: React.FunctionComponent<Props> = (props: Props) => {
    let {
        coinId,
        face,
        className,
    } = props;

    let def = coinDefinitions[coinId];

    return <Row className={`${className}`}>
        <Col sm={12} md={8} className='p-1'>
            <ListGroup>
                {def.areas[face].descriptions.map((aspect, index) => {
                    return <ListGroup.Item key={`aspect-${index}`}>{aspect}</ListGroup.Item>
                })}
            </ListGroup>
        </Col>
        <CenteredFlexCol sm={12} md={4}>
            {/*TODO: icons*/}
            {face === CoinFace.Heads ? `Heads` : `Tails`}
        </CenteredFlexCol>
    </Row>
};