
// ------------------------------------
// Constants
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------

export enum ModalActions {
    SetOpen = '@@modal/SetOpen',
    SetClosed = '@@modal/SetClosed',
    UserClose = '@@modal/UserClose',
    SetProperties = '@@modal/SetProperties',
}

export enum SagaModalActions {
    ConfirmSaga = '@@modal/ConfirmSaga',
    YesNoSaga = '@@modal/YesNoSaga',
    InputSaga = '@@modal/InputSaga',
    OutputSaga = '@@modal/OutputSaga',
}

export enum ModalType {
    Confirm = '@@modalType/Confirm',
    YesNo = '@@modalType/YesNo',
    Input = '@@modalType/Input',
    Output = '@@modalType/Output',
}

interface ModalAction {
    type: ModalActions;
}

interface ModalSagaAction {
    type: SagaModalActions;
}

interface SetOpenAction extends ModalAction {
    type: ModalActions.SetOpen;
}
export const setOpen = (): SetOpenAction => {
    return {
        type: ModalActions.SetOpen,
    };
};

interface SetClosedAction extends ModalAction {
    type: ModalActions.SetClosed;
}
export const setClosed = (): SetClosedAction => {
    return {
        type: ModalActions.SetClosed,
    };
};

export interface ModalCloseResult {
    type: ModalType;
    cancelled: boolean;
    confirmed: boolean;
}

export interface ConfirmResult extends ModalCloseResult {
    type: ModalType.Confirm;
}

export interface YesNoResult extends ModalCloseResult {
    type: ModalType.YesNo;
    no: boolean;
}

export interface InputResult extends ModalCloseResult {
    type: ModalType.Input;
    input: string;
}

export interface OutputResult extends ModalCloseResult {
    type: ModalType.Output;
}

export interface UserCloseAction extends ModalAction {
    type: ModalActions.UserClose;
    result: ModalCloseResult;
}
export const userClose = (result: ModalCloseResult): UserCloseAction => {
    return {
        type: ModalActions.UserClose,
        result,
    };
};

export interface SetPropertiesAction extends ModalAction {
    type: ModalActions.SetProperties;
    modalType: ModalType;
    title: string;
    descriptionText: string | null;
    confirmButtonLabel: string | null;
    yesButtonLabel: string | null;
    noButtonLabel: string | null;
    outputText: string | null;
}
export const setProperties = (
    modalType: ModalType,
    title: string,
    descriptionText: string | null,
    confirmButtonLabel: string | null,
    yesButtonLabel: string | null,
    noButtonLabel: string | null,
    outputText: string | null,
): SetPropertiesAction => {
    return {
        type: ModalActions.SetProperties,
        modalType,
        title,
        descriptionText,
        confirmButtonLabel,
        yesButtonLabel,
        noButtonLabel,
        outputText,
    };
};

export interface ConfirmSagaAction extends ModalSagaAction {
    type: SagaModalActions.ConfirmSaga;
    title: string;
    text: string;
    buttonLabel: string;
}
export const confirmSaga = (title: string, text: string, buttonLabel: string = 'Ok'): ConfirmSagaAction => {
    return {
        type: SagaModalActions.ConfirmSaga,
        title,
        text,
        buttonLabel,
    };
};

export interface YesNoSagaAction extends ModalSagaAction {
    type: SagaModalActions.YesNoSaga;
    title: string;
    text: string;
    yesButtonLabel: string;
    noButtonLabel: string;
}
export const yesNoSaga = (title: string, text: string, yesButtonLabel: string = 'Yes', noButtonLabel: string = 'No'): YesNoSagaAction => {
    return {
        type: SagaModalActions.YesNoSaga,
        title,
        text,
        yesButtonLabel,
        noButtonLabel,
    };
};

export interface InputSagaAction extends ModalSagaAction {
    type: SagaModalActions.InputSaga;
    title: string;
    text: string;
    buttonLabel: string;
}
export const inputSaga = (title: string, text: string, buttonLabel: string): InputSagaAction => {
    return {
        type: SagaModalActions.InputSaga,
        title,
        text,
        buttonLabel,
    };
};

export interface OutputSagaAction extends ModalSagaAction {
    type: SagaModalActions.OutputSaga;
    title: string;
    text: string;
    output: string;
    buttonLabel: string;
}
export const outputSaga = (title: string, text: string, output: string, buttonLabel: string): OutputSagaAction => {
    return {
        type: SagaModalActions.OutputSaga,
        title,
        text,
        output,
        buttonLabel,
    };
};

export type ModalActionTypes = SetOpenAction | SetClosedAction | UserCloseAction | SetPropertiesAction;

export interface ModalState {
    modalType: ModalType;
    isOpen: boolean;
    title: string;
    descriptionText: string | null;
    confirmButtonLabel: string | null;
    yesButtonLabel: string | null;
    noButtonLabel: string | null;
    outputText: string | null;
    result: ModalCloseResult | null;
}

const initialState: ModalState = {
    modalType: ModalType.Confirm,
    isOpen: false,
    title: 'Modal',
    descriptionText: null,
    confirmButtonLabel: null,
    yesButtonLabel: null,
    noButtonLabel: null,
    outputText: null,
    result: null,
};

export function modalReducer(state = initialState, action: ModalActionTypes): ModalState {
    switch (action.type) {
        case ModalActions.SetOpen: {
            return {
                ...state,
                isOpen: true,
            };
        }
        case ModalActions.SetClosed: {
            return {
                ...state,
                isOpen: false,
            };
        }
        case ModalActions.UserClose: {
            return {
                ...state,
                result: action.result,
            };
        }
        case ModalActions.SetProperties: {
            return {
                ...state,
                modalType: action.modalType,
                title: action.title,
                descriptionText: action.descriptionText,
                confirmButtonLabel: action.confirmButtonLabel,
                yesButtonLabel: action.yesButtonLabel,
                noButtonLabel: action.noButtonLabel,
                outputText: action.outputText,
            };
        }
        default:
            return state
    }
}
