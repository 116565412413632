import React from 'react';
import {coinDefinitions, CoinId, CoinFace} from "../../../lib/sharedConstants";
import {Col, ListGroup, Row} from "react-bootstrap";

interface Props {
    coinId: CoinId;
    face: CoinFace;
    isSelected: boolean;
}

export const PrimaryCoin: React.FunctionComponent<Props> = (props: Props) => {
    let {
        coinId,
        face,
        isSelected,
    } = props;

    let def = coinDefinitions[coinId];

    return <>
        <Row>
            <h4>{def.name}</h4>
        </Row>
        <Row className={`selection-box ${isSelected ? 'selected' : 'unselected'}`}>
            <h1>{def.name} {face} icon</h1>
        </Row>
    </>;
};