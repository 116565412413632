import React from 'react';
import {Col, ColProps} from "react-bootstrap";

interface Props extends ColProps {
  isRow?: boolean;
  className?: string;
  justify?: string;
  align?: string;
}

export const CenteredFlexCol: React.FunctionComponent<Props> = (props) => {
  let {
    isRow = true,
    className = '',
    justify = 'center',
    align = 'center',
    children,
    ...rest
  } = props;
  return <Col {...rest} className={`${className} d-flex flex-${isRow ? 'row' : 'column'} 
    justify-content-${justify} align-items-${align}`}>
    {children}
  </Col>;
};
