import React from 'react';
import {Redirect, RouteComponentProps} from 'react-router-dom'
import {connect} from 'react-redux';
import {AppState} from "../../modules";
import {bindActionCreators} from "redux";
import {SingleSelectionPage} from "./components/pages/SingleSelectionPage";
import {CoinFace, CoinId, coinSelectionSteps} from "../../lib/sharedConstants";
import {
    indexedSteps,
    selectCoinFaces,
    selectFirstCoin,
    selectNemesis,
    selectSecondCoin,
    Step
} from "../../modules/coins";
import {DualSelectionPage} from "./components/pages/DualSelectionPage";
import {log} from "../../lib/logger";
import {ResultPage} from "./components/pages/ResultPage";

interface MatchParams {
    step: string;
}

type Props = SelectorProps & RouteComponentProps<MatchParams>;

class SelectorDisplay extends React.Component<Props> {
    render() {
        let {
            firstSelectedCoin,
            secondSelectedCoin,
            selectedCoinFaces,
            selectedNemesis,
        } = this.props;
        let {
            step: stepIndex,
        } = this.props.match.params;

        let stepEnum = indexedSteps[parseInt(stepIndex, 10) - 1];
        switch (stepEnum) {
            case Step.FirstSet: {
                return <>
                    <SingleSelectionPage
                        step={stepEnum}
                        leftCoin={coinSelectionSteps[stepEnum][0]}
                        rightCoin={coinSelectionSteps[stepEnum][1]}
                        {...this.props}
                    />
                </>;
            }
            case Step.SecondSet: {
                if (!firstSelectedCoin) {
                    log(`First selected coin not found, reverting to first step`);
                    return <Redirect to='/selector/1' />
                }
                return <>
                    <SingleSelectionPage
                        step={stepEnum}
                        leftCoin={coinSelectionSteps[stepEnum][0]}
                        rightCoin={coinSelectionSteps[stepEnum][1]}
                        {...this.props}
                    />
                </>;
            }
            case Step.Flip: {
                if (!firstSelectedCoin || !secondSelectedCoin) {
                    log(`First or second selected coin not found, reverting to second step`);
                    return <Redirect to='/selector/2' />
                }
                return <>
                    <DualSelectionPage
                        leftCoin={firstSelectedCoin}
                        rightCoin={secondSelectedCoin}
                        displayBothFaces={true}
                        {...this.props}
                    />
                </>;
            }
            case Step.Split: {
                if (!firstSelectedCoin || !secondSelectedCoin || Object.keys(selectedCoinFaces).length !== 2) {
                    log(`First or second selected coin not found or selectedCoinFaces not of length 2 ` +
                     `(${Object.keys(selectedCoinFaces).length}), reverting to third step`);
                    return <Redirect to='/selector/3' />
                }
                return <>
                    <DualSelectionPage
                        leftCoin={firstSelectedCoin}
                        rightCoin={secondSelectedCoin}
                        displayBothFaces={false}
                        {...this.props}
                    />
                </>;
            }
            case Step.Nemesis: {
                if (!firstSelectedCoin || !secondSelectedCoin || Object.keys(selectedCoinFaces).length !== 2) {
                    log(`First or second selected coin not found or selectedCoinFaces not of length 2 ` +
                        `(${Object.keys(selectedCoinFaces).length}), reverting to third step`);
                    return <Redirect to='/selector/3' />
                }
                return <>
                    <h1>TODO</h1>
                    {/*<DualSelectionPage*/}
                    {/*    leftCoin={CoinId.Exploration}*/}
                    {/*    rightCoin={CoinId.Direction}*/}
                    {/*    displayBothFaces={false}*/}
                    {/*    {...this.props}*/}
                    {/*/>*/}
                </>;
            }
            case Step.Result: {
                if (!firstSelectedCoin || !secondSelectedCoin || Object.keys(selectedCoinFaces).length !== 1) {
                    log(`First or second selected coin not found or selectedCoinFaces not of length 1 ` +
                        `(${Object.keys(selectedCoinFaces).length}), reverting to fourth step`);
                    return <Redirect to='/selector/4' />
                }
                return <>
                    <ResultPage
                        selection={selectedCoinFaces[0]}
                    />
                </>;
            }
            default:
                throw new Error(`Invalid selector step index ${stepIndex}`);
        }
    }
}

export type SelectorProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const mapStateToProps = (state: AppState) => {
    return {
        ...state.coinReducer,
    };
};

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    selectFirstCoin,
    selectSecondCoin,
    selectCoinFaces: selectCoinFaces,
    selectNemesis,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectorDisplay)


// class Home extends Component {
//     render() {
//         let {
//             coinSlots,
//             coinSides,
//             moveCoin,
//             flipCoin,
//         } = this.props;
//         let coinsBySlot = getCoinsBySlot(coinSlots);
//         let sideErrors = this.getSideErrors(coinsBySlot, coinSides);
//         let colorErrors = this.getColorErrors(coinsBySlot);
//
//         return <Container fluid={true} className=''>
//             {coinsBySlot.map((coinIndex, slotIndex) => {
//                 return <CoinRow
//                     key={`coin-row-${slotIndex}`}
//                     coinIndex={coinIndex}
//                     slotIndex={slotIndex}
//                     topCoinSide={coinSides[coinIndex]}
//                     moveCoin={moveCoin}
//                     flipCoin={flipCoin}
//                     hasSideError={sideErrors[slotIndex]}
//                     hasColorError={colorErrors[slotIndex]}
//                 />;
//             })}
//         </Container>;
//     }
//
//     getSideErrors(coinsBySlot, coinSides) {
//         let slotsWithErrors = Array(4).fill(false);
//         let sidesBySlot = coinsBySlot.map((coinIndex) => {
//             return coinSides[coinIndex];
//         });
//
//         for (let i = 0; i < 3; i++) {
//             if (sidesBySlot[i] === sidesBySlot[i + 1]) {
//                 slotsWithErrors[i] = true;
//             }
//         }
//         return slotsWithErrors;
//     }
//
//     getColorErrors(coinsBySlot) {
//         let slotsWithErrors = Array(4).fill(false);
//         let colorsBySlot = coinsBySlot.map((coinIndex) => {
//             return coins[coinIndex].color;
//         });
//
//         if (colorsBySlot[1] !== colorsBySlot[2]) {
//             slotsWithErrors[1] = true;
//         }
//         return slotsWithErrors;
//     }
// }
//
// const mapStateToProps = (state) => {
//     return {
//         ...state.coinReducer,
//     };
// };
//
// const mapDispatchToProps = {
//     moveCoin,
//     flipCoin,
//     // changePage: () => push('/about-us')
// };
//
// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(Home)
//
