import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import Selector from '../selector'
import {Container} from "react-bootstrap";

const App = () => (
    <Container fluid={true} className='top-level-container'>
        <Switch>
            <Route exact path="/" render={() => (
                <Redirect to="/selector/1"/>
            )}/>

            <Route exact path="/selector" render={() => (
                <Redirect to="/selector/1"/>
            )}/>
            <Route path="/selector/:step" component={Selector}/>
        </Switch>
    </Container>
);

export default App
