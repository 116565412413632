import {SagaIterator} from "redux-saga";
import {log} from "../lib/logger";
import {put, select, take, takeLatest} from "redux-saga/effects";
import {AppState} from "../modules";
import {
    ConfirmResult,
    ConfirmSagaAction,
    InputResult,
    InputSagaAction,
    ModalActions,
    ModalType,
    OutputResult,
    OutputSagaAction,
    SagaModalActions,
    setClosed,
    setOpen,
    setProperties,
    SetPropertiesAction,
    UserCloseAction,
    YesNoResult,
    YesNoSagaAction
} from "../modules/modal";

export function* modalSagas(): SagaIterator {
    log(`Launching modal sagas`);
    yield takeLatest(SagaModalActions.ConfirmSaga, confirmSaga);
    yield takeLatest(SagaModalActions.YesNoSaga, yesNoSaga);
    yield takeLatest(SagaModalActions.InputSaga, inputSaga);
    yield takeLatest(SagaModalActions.OutputSaga, outputSaga);
}

function* confirmSaga(action: ConfirmSagaAction): SagaIterator {
    let result = (yield* displayAndWaitForModal('confirm', setProperties(
        ModalType.Confirm,
        action.title,
        action.text,
        action.buttonLabel,
        null,
        null,
        null,
    ))) as ConfirmResult;
}

function* yesNoSaga(action: YesNoSagaAction): SagaIterator {
    let result = (yield* displayAndWaitForModal('yes no', setProperties(
        ModalType.YesNo,
        action.title,
        action.text,
        null,
        action.yesButtonLabel,
        action.noButtonLabel,
        null,
    ))) as YesNoResult;
}

function* inputSaga(action: InputSagaAction): SagaIterator {
    let result = (yield* displayAndWaitForModal('input', setProperties(
        ModalType.Input,
        action.title,
        action.text,
        action.buttonLabel,
        null,
        null,
        null,
    ))) as InputResult;
}

function* outputSaga(action: OutputSagaAction): SagaIterator {
    let result = (yield* displayAndWaitForModal('output', setProperties(
        ModalType.Output,
        action.title,
        action.text,
        action.buttonLabel,
        null,
        null,
        action.output,
    ))) as OutputResult;
}

export function* displayAndWaitForModal(sagaName: string, setPropertiesAction: SetPropertiesAction) {
    log(`Launching ${sagaName} modal saga`);
    let state = (yield select()) as AppState;

    log(`${sagaName} modal display: ${state.modalReducer.isOpen}`);
    // Display the modal
    yield put(setPropertiesAction);
    yield put(setOpen());

    // Get the result
    let closeAction = (yield* waitForClose()) as UserCloseAction;
    let result = closeAction.result;
    yield put(setClosed());
    return result;
}

export function* waitForClose() {
    log(`Waiting for modal close`);
    let closeAction = (yield take(ModalActions.UserClose)) as UserCloseAction;
    log(`User closed modal, continuing`);
    return closeAction;
}
