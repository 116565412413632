import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import {coinDefinitions, CoinFace, CoinId, DisplaySide, getAspectsDisplayString} from "../../../../lib/sharedConstants";
import {SelectorProps} from "../../index";
import {CenteredRow} from "../../../../lib/components/CenteredRow";
import {Aspect} from "../Aspect";
import {CenteredFlexCol} from "../../../../lib/components/CenteredFlexCol";
import {Link} from "react-router-dom";
import {PrimaryCoin} from "../PrimaryCoin";
import {indexedSteps, Step} from "../../../../modules/coins";

interface Props {
    coinId: CoinId;
    displaySide: DisplaySide;
    step: Step;
}

export class SingleSelectionPanel extends Component<Props & SelectorProps> {
    render() {
        let {
            coinId,
            displaySide,
        } = this.props;
        let def = coinDefinitions[coinId];

        return <Col
            className={`${displaySide === DisplaySide.Left ? 'left-half-screen' : 'right-half-screen'}`}
            sm={12} md={6}
        >
            <CenteredRow className='p-1'>
                <h4>The {def.name}, {getAspectsDisplayString(def)} of:</h4>
            </CenteredRow>
            <Row className='p-1'>
                {this.renderContent()}
            </Row>
        </Col>;
    }

    renderContent() {
        let {
            coinId,
            displaySide,
            step,
        } = this.props;
        let currentStepIndex = indexedSteps.indexOf(step);
        if (currentStepIndex === -1) {
            throw new Error(`Couldn't find index for step ${step}`);
        }
        // +1 for a zero indexed array, +1 again to advance to the next step
        let nextStepIndex = currentStepIndex + 2;
        switch (displaySide) {
            case DisplaySide.Left:
                return <>
                    <Col sm={12} md={6}>
                        <Aspect coinId={coinId} face={CoinFace.Heads}/>
                        <Aspect coinId={coinId} face={CoinFace.Tails}/>
                    </Col>
                    <CenteredFlexCol isRow={false} justify='end' align='center' sm={12} md={6}>
                        <Link
                            to={`${nextStepIndex}`}
                            onClick={this.coinClickHandler.bind(this)}
                        >
                            <PrimaryCoin coinId={coinId} face={CoinFace.Heads} isSelected={this.isSelected()}/>
                        </Link>
                    </CenteredFlexCol>
                </>;
            case DisplaySide.Right:
                return <>
                    <CenteredFlexCol isRow={false} justify='end' align='center' sm={12} md={6}>
                        <Link
                            to={`${nextStepIndex}`}
                            onClick={this.coinClickHandler.bind(this)}
                        >
                            <PrimaryCoin coinId={coinId} face={CoinFace.Heads} isSelected={this.isSelected()}/>
                        </Link>
                    </CenteredFlexCol>
                    <Col sm={12} md={6}>
                        <Aspect coinId={coinId} face={CoinFace.Heads}/>
                        <Aspect coinId={coinId} face={CoinFace.Tails}/>
                    </Col>
                </>;
        }
    }

    coinClickHandler() {
        let {
            coinId,
            selectFirstCoin,
            selectSecondCoin,
            step,
        } = this.props;
        switch (step) {
            case Step.FirstSet:
                selectFirstCoin(coinId);
                break;
            case Step.SecondSet:
                selectSecondCoin(coinId);
                break;
            default:
                throw new Error(`Invalid step ${step} for SingleSelectionPanel`);
        }
    }

    isSelected(): boolean {
        let {
            coinId,
            firstSelectedCoin,
            secondSelectedCoin,
            step,
        } = this.props;
        switch (step) {
            case Step.FirstSet:
                return firstSelectedCoin === coinId;
            case Step.SecondSet:
                return secondSelectedCoin === coinId;
            default:
                throw new Error(`Invalid step ${step} for SingleSelectionPanel`);
        }
    }
}
